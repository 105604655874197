import Cookies from 'cookies';

export enum CookieNames {
  jwt = 'jwt',
  refresh_token = 'refresh_token',
  vytal_cookie_consent = 'vytal_cookie_consent',
}

export function setAuthCookies(
  cookies: Cookies,
  jwt: string,
  refreshToken: string,
) {
  const dateInOneYear = new Date(Date.now() + 1000 * 60 * 60 * 24 * 365);
  cookies.set(CookieNames.jwt, jwt, {
    httpOnly: false, // we also want to use the token on the client
    sameSite: 'none',
    secure: true,
    expires: dateInOneYear,
  });
  cookies.set(CookieNames.refresh_token, refreshToken, {
    httpOnly: false,
    sameSite: 'none',
    secure: true,
    expires: dateInOneYear,
  });
}
