import { useEffect, useRef, useState } from 'react';
import type { LottiePlayer } from 'lottie-web';

/*
We load the library and the anim json asynchronously/dynamically for better performance,
as lottie files can be heavy.
Code extracted from => https://stackoverflow.com/questions/69282996/lottie-files-reducing-performance-on-nextjs-app
*/

export const LottieLoader = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [lottie, setLottie] = useState<LottiePlayer | null>(null);

  useEffect(() => {
    import('lottie-web').then((Lottie) => setLottie(Lottie.default));
  }, []);

  useEffect(() => {
    if (lottie && ref.current) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        //animation file needs to be inside public folder
        path: '/img/Lottie-Loader-fast-data.json',
      });

      return () => animation.destroy();
    }
  }, [lottie]);

  return <div ref={ref} />;
};
