import '../styles/globals.css';
import 'tailwindcss/tailwind.css';
import type { AppProps } from 'next/app';
import { CookieConsentModal } from '../components/CookieConsent/CookieConsent';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { CookieNames } from '../utils/cookies';
import { DefaultPageProps } from '../services/authMiddleware';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';

function MyApp({ Component, pageProps }: AppProps) {
  const { requestCookieConsent, cookieConsentGiven: initCookieConsentGiven } =
    pageProps as DefaultPageProps;
  const [cookies, setCookie] = useCookies([CookieNames.vytal_cookie_consent]);
  const [cookieConsentGiven, setAcceptedCookies] = useState<boolean>(
    initCookieConsentGiven,
  );

  const onCookieAccepted = () => {
    setAcceptedCookies(true);
    const dateInOneYear = new Date(Date.now() + 1000 * 60 * 60 * 24 * 365);
    setCookie(CookieNames.vytal_cookie_consent, true, {
      path: '/',
      sameSite: 'none',
      secure: true,
      // expires in 1 year
      expires: dateInOneYear,
    });
  };

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      {requestCookieConsent && !cookieConsentGiven && (
        <CookieConsentModal onAccept={onCookieAccepted} />
      )}
      <Component
        {...pageProps}
        cookieConsentGiven={cookieConsentGiven}
        requestCookieConsent={requestCookieConsent}
      />
    </>
  );
}

export default appWithTranslation(MyApp);
