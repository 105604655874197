import React from 'react';
import styles from './Button.module.css';
import { LottieLoader } from '../LottieLoader';
import { useTranslation } from 'next-i18next';
import TRANSLATION_FILES from '../../public/locales/constants';

export enum ButtonType {
  primary = 'primary', // green
  secondary = 'secondary', // outlined
}

type ButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean;
  loading?: boolean;
  label?: string;
  addedClassNames?: string;
  type?: ButtonType;
};

export function Button({
  onClick,
  label,
  addedClassNames = '',
  disabled = false,
  loading = false,
  type = ButtonType.primary,
  ...props
}: ButtonProps) {
  const { t } = useTranslation(TRANSLATION_FILES.common);

  // default button color is green
  const buttonStyle = loading
    ? styles.blackButton
    : type === ButtonType.primary
      ? styles.greenButton
      : styles.outline;
  const text = loading ? t('pleaseWait') : (label ?? t('continue'));
  return (
    <button
      className={`${buttonStyle} ${styles.buttonStyle} ${addedClassNames}`}
      onClick={onClick}
      disabled={disabled || loading}
      {...props}
    >
      {loading && (
        <div className={styles.loadingSpinner}>
          <LottieLoader />
        </div>
      )}
      {text?.toUpperCase()}
    </button>
  );
}
