const TRANSLATION_FILES = {
  accountOverview: 'account-overview',
  common: 'common',
  errorMsg: 'error-msg',
  leftScreen: 'left-screen',
  logoutPage: 'logout-page',
  payment: 'payment',
  privacyPage: 'privacy-page',
  register: 'register',
  signInUp: 'sign-in-up',
  vytalBowls: 'vytal-bowls',
};

export default TRANSLATION_FILES;
