import React, { MouseEventHandler } from 'react';
import { Button, ButtonType } from '../Buttons/Button';

import cookieConsentStyles from './CookieConsent.module.css';

export function CookieConsentModal({
  onAccept,
}: {
  onAccept: MouseEventHandler<HTMLElement>;
}) {
  return (
    <div className={cookieConsentStyles.CookieConsentModalContainer}>
      <div className={cookieConsentStyles.CookieConsentModal}>
        <h2 className="headline mb-3">Cookies akzeptieren</h2>

        <div className="flex flex-col">
          <p className="mb-3 vytal-dark">
            Wir benutzen nur funktionale Cookies und respektieren deine
            Privatsphäre.
          </p>
          <Button
            addedClassNames="self-end"
            onClick={onAccept}
            type={ButtonType.primary}
            label="Okay"
          />
        </div>
      </div>
    </div>
  );
}
